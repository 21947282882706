// ----------------------------------------
// Landing page
// ----------------------------------------

import Flickity from 'flickity';

//
// Related items
//

new Flickity('#landing-page-related', {
    contain: true,
    watchCSS: true,
    groupCells: 2,
    pageDots: false,
});
