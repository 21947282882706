// ----------------------------------------
// ATDW entries
// ----------------------------------------

import Flickity from 'flickity';
import $ from 'jquery';
import '@chenfengyuan/datepicker';
import '@chenfengyuan/datepicker/dist/datepicker.min.css';

//
// Gallery
//

new Flickity('#atdw-gallery', {
    contain: true,
    prevNextButtons: false,
    adaptiveHeight: true,
    lazyLoad: 2,
});

$('[data-toggle="datepicker"]').datepicker({
    language: 'en-GB',
    format: 'dd/mm/yyyy',
});

$('.filter-button button[type="reset"]').click(function(event) {
    event.preventDefault();

    const $form = $(this).closest('form');

    $form.find('select, input').each(function() {
        $(this).val('');
    });

    $form.submit();

    return false;
});
